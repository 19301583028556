import React from "react"
import "../css/style.css"
import { Link } from "gatsby"
import logo from "../images/logo.png"

export default function Burgermenu() {
  return (
    <section className="section1 burgernavbar-style burgermenu-display">
      <input id="main-menu-checkbox" type="checkbox" />

      <header className="header-area">
        {" "}
        <div className="burgernav-logo-div">
          <Link to="/">
            <img className="navbar-logo-burger" alt="" src={logo} />
          </Link>
        </div>
        <div className="grid-container-burgernav">
          <div className="burger-grid">
            <label
              for="main-menu-checkbox"
              className="zoom menu-toggle label"
              id="main-menu-toggle"
            >
              <span className="sr-only">Open</span>
              <span className="fa fa-bars"></span>
            </label>

            <div className="d-inline-flex">
              <nav
                id="main-menu"
                role="navigation"
                className="main-menu"
                aria-label="Main menu"
              >
                <div className="burgernav-box">
                  <Link to="/" className="burgernav-title">
                    INICIO
                  </Link>
                  <Link to="#nosotros" className="burgernav-title">
                    NOSOTROS
                  </Link>
                  <Link to="#servicios" className="burgernav-title">
                    SERVICIOS
                  </Link>
                  <Link to="#proyectos" className="burgernav-title">
                    PROYECTOS
                  </Link>
                  <Link to="#contacto" className="burgernav-title">
                    CONTACTO
                  </Link>
                </div>
              </nav>
              <label
                for="main-menu-checkbox"
                className="backdrop"
                tabindex="-1"
                aria-hidden="true"
                hidden
              ></label>
            </div>
          </div>
        </div>
      </header>
    </section>
  )
}
