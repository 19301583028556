import React from "react"
import "../css/style.css"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import { Link } from "gatsby"

export default function Navbar() {
  const data = useStaticQuery(graphql`
    query NAVBAR {
      logo: file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          fluid {
            src
            srcWebp
            aspectRatio
            base64
          }
        }
      }
    }
  `)

  return (
    <section className="navbar-section navbar-display ">
      <div className="navbar-grid">
        <div className="navbar-logo-sec">
          <Link to="/">
            <Img
              className="navbar-logo"
              alt=""
              fluid={data.logo.childImageSharp.fluid}
            />
          </Link>
        </div>
        <div></div>
        <div>
          <Link to="/" className="navbar-link">
            <p>INICIO</p>
          </Link>
        </div>
        <div>
          <Link to="#nosotros" className="navbar-link">
            <p>NOSOTROS</p>
          </Link>
        </div>
        <div>
          <Link to="#servicios" className="navbar-link">
            <p>SERVICIOS</p>
          </Link>
        </div>
        <div>
          <Link to="#proyectos" className="navbar-link">
            <p>PROYECTOS</p>
          </Link>
        </div>
        <div>
          <Link to="#contacto" className="navbar-link">
            <p>CONTACTO</p>
          </Link>
        </div>
        <div>
          <p className="navbar-p">
            Contactanos!
            <br />
            <a href="tel: 5575952260">(55) 75-95-2260</a>
          </p>
        </div>
      </div>
    </section>
  )
}
