import React from "react"
import "../css/style.css"
import { Link } from "gatsby"

export default function Footer() {
  return (
    <footer className="footer-section">
      <div className="footer-line-rights" />
      <div className="footer-div-rights">
        <p className="footer-rights">
          RBL Ingenieria ©2021 - Todos los Derechos Reservados.
        </p>
        <Link to="/aviso_de_privacidad" className="footer-advise">
          <p className="footer-advise">Aviso de Privacidad</p>
        </Link>
      </div>
    </footer>
  )
}
